// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/uprate-primary-button.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/uprate-primary-button.tsx");
  import.meta.hot.lastModified = "1712947911928.2556";
}
// REMIX HMR END

import { Button, useColorModeValue } from "@chakra-ui/react";
import { UprateB4 } from "./uprate-styles";
export default function UpratePrimaryButton({
  children,
  ...rest
}) {
  _s();
  return <Button border="1px" borderColor={useColorModeValue("purple.300", "purple.700")} backgroundColor={useColorModeValue("purple.200", "purple.800")} borderRadius={"sm"} borderTopLeftRadius={"lg"} borderBottomRightRadius={"lg"} size={"sm"} _hover={{
    backgroundColor: useColorModeValue("purple.300", "purple.700")
  }} {...rest}>
      <UprateB4>{children}</UprateB4>
    </Button>;
}
_s(UpratePrimaryButton, "6SPWDKKJx3x6/piDRqtxmfjVGnY=", false, function () {
  return [useColorModeValue, useColorModeValue, useColorModeValue];
});
_c = UpratePrimaryButton;
var _c;
$RefreshReg$(_c, "UpratePrimaryButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;